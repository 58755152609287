export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
};

export const success = {
  light: '#62E0C1',
  main: '#26C6A7',
  dark: '#1A8D78',
  contrastText: '#FFFFFF',
};

export const info = {
  light: '#6CAEE6',
  main: '#3A8EDD',
  dark: '#2B6CAB',
  contrastText: '#FFFFFF',
};
export const warning = {
  light: '#FFD37A',
  main: '#FFB447',
  dark: '#B77F30',
  contrastText: '#FFFFFF',
};

export const error = {
  light: '#FF7A7A',
  main: '#FF3D3D',
  dark: '#B02C2C',
  contrastText: '#FFFFFF',
};
