import { createAsyncThunk } from '@reduxjs/toolkit';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { SignUpFormValues } from '../../features/authentication/components/sign-up-form/sign-up-form-values';
import { AuthService } from '../../features/authentication/services';
import { AuthUser } from '../../models/auth/auth-user';
import { estimationRunsActions } from '../estimation-run';
import { estimationTasksActions } from '../estimation-tasks';

const authService = new AuthService();

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (props: { formValues: SignUpFormValues; onSuccess: () => void }): Promise<void> => {
    const { formValues, onSuccess } = props;

    await authService.signUp(formValues.email, formValues.password, formValues.name);
    onSuccess();
  },
);

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignup',
  async (props: { email: string; code: string; onSuccess: () => void }): Promise<void> => {
    await authService.confirmSignUp(props.email, props.code);
    props.onSuccess();
  },
);

export const resendSignUpCode = createAsyncThunk(
  'auth/resendSignUpCode',
  async (props: { email: string }): Promise<void> => {
    await authService.resendSignUpCode(props.email);
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (props: {
    email: string;
    password: string;
    onSuccess: () => void;
  }): Promise<{ cognitoUser: CognitoUser; authUser?: AuthUser }> => {
    const cognitoUser = await authService.signIn(props.email, props.password);
    props.onSuccess();

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return { cognitoUser };
    }

    const idPayload = cognitoUser.getSignInUserSession()?.getIdToken()?.payload;
    const authUser: AuthUser = {
      sub: idPayload?.sub as string,
      email: idPayload?.email as string,
      name: idPayload?.name as string,
    };

    return { cognitoUser, authUser };
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (never, { dispatch }): Promise<void> => {
    await authService.signOut();
    dispatch(estimationTasksActions.resetStateToInitial());
    dispatch(estimationRunsActions.resetStateToInitial());
  },
);

export const completeNewPassword = createAsyncThunk(
  'auth/completeNewPassword',
  async (props: {
    user: CognitoUser;
    newPassword: string;
    onSuccess: () => void;
  }): Promise<void> => {
    await authService.completeNewPassword(props.user, props.newPassword);
    props.onSuccess();
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (props: { email: string; onSuccess: () => void }): Promise<void> => {
    await authService.forgotPassword(props.email);
    props.onSuccess();
  },
);

export const forgotPasswordSubmit = createAsyncThunk(
  'auth/forgotPasswordSubmit',
  async (props: {
    email: string;
    code: string;
    newPassword: string;
    onSuccess: () => void;
  }): Promise<void> => {
    await authService.forgotPasswordSubmit(props.email, props.code, props.newPassword);
    props.onSuccess();
  },
);

export const loadCurrentSession = createAsyncThunk(
  'auth/loadCurrentSession',
  async (): Promise<AuthUser> => {
    const cognitoUser = await authService.getCurrentUser();
    const idPayload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!idPayload) {
      throw new Error('Id Token Invalid');
    }
    return {
      sub: idPayload.sub as string,
      email: idPayload.email as string,
      name: idPayload.name as string,
    };
  },
);
