import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export class AuthService {
  /**
   *  Sign up as a new user
   * @param email The user's email
   * @param password The user's password
   * @param name The user's name
   */
  public async signUp(email: string, password: string, name: string): Promise<ISignUpResult> {
    return await Auth.signUp({
      username: AuthService.transformEmail(email),
      password,
      attributes: {
        name,
        // All user accounts created will belong to the Syracom company by default.
        'custom:companyId': 'syracom',
      },
      autoSignIn: {
        enabled: true,
      },
    });
  }

  /**
   * Confirm the user's email after sign up
   * @param email The user's email
   * @param code The confirmation code
   */
  public async confirmSignUp(email: string, code: string): Promise<void> {
    await Auth.confirmSignUp(AuthService.transformEmail(email), code);
  }

  /**
   * Resend the email confirmation code
   * @param email The user's email
   */
  public async resendSignUpCode(email: string): Promise<void> {
    await Auth.resendSignUp(AuthService.transformEmail(email));
  }

  /**
   * Sign in using email and password
   * @param email The user's email
   * @param password The user's password
   */
  public async signIn(email: string, password: string): Promise<CognitoUser> {
    return await Auth.signIn(AuthService.transformEmail(email), password);
  }

  /**
   * Sign out
   */
  public async signOut(): Promise<void> {
    await Auth.signOut();
  }

  /** Change the user's password on first login
   *
   * @param user The cognito user
   * @param newPassword The user's new password
   */
  public async completeNewPassword(user: CognitoUser, newPassword: string): Promise<void> {
    await Auth.completeNewPassword(user, newPassword);
  }

  /**
   * Send the confirmation code to the user's email to allow them to reset their password
   * @param email The user's email
   */
  public async forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(AuthService.transformEmail(email));
  }

  /**
   * Reset the user's password
   * @param email The user's email
   * @param code the confirmation code
   * @param newPassword The user's new password
   */
  public async forgotPasswordSubmit(
    email: string,
    code: string,
    newPassword: string,
  ): Promise<void> {
    await Auth.forgotPasswordSubmit(AuthService.transformEmail(email), code, newPassword);
  }

  /**
   * Load the current user form cognito
   */
  public async getCurrentUser(): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser();
  }

  /**
   * Transforms an email to lowercase so that the auth-layout is case-insensitive
   * @param email The user's email
   * @private
   */
  private static transformEmail(email: string): string {
    return email.trim().toLocaleLowerCase();
  }
}
