import { useTranslation } from 'react-i18next';

export const useLanguage = (): {
  changeToGerman: () => void;
  changeToEnglish: () => void;
  language: string;
} => {
  const { i18n } = useTranslation();
  const changeToGerman = () => {
    i18n.changeLanguage('de').catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  };
  const changeToEnglish = () => {
    i18n.changeLanguage('en').catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  };

  return {
    changeToGerman,
    changeToEnglish,
    language: i18n.language,
  };
};
