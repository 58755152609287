import { CognitoUser } from 'amazon-cognito-identity-js';
import { useMemo } from 'react';

import { useAppDispatch } from '../../../hooks/store';
import {
  authActions,
  completeNewPassword,
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
  loadCurrentSession,
  resendSignUpCode,
  signIn,
  signOut,
  signUp,
} from '../../../store/auth';
import { SignUpFormValues } from '../components/sign-up-form/sign-up-form-values';

export const useAuthActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      signUp: (formValues: SignUpFormValues, onSuccess: () => void) =>
        dispatch(signUp({ formValues, onSuccess })),
      confirmSignUp: (email: string, code: string, onSuccess: () => void) =>
        dispatch(confirmSignUp({ email, code, onSuccess })),
      resendSignUpCode: (email: string) => dispatch(resendSignUpCode({ email })),
      signIn: (email: string, password: string, onSuccess: () => void) =>
        dispatch(signIn({ email, password, onSuccess })),
      signOut: () => dispatch(signOut()),
      completeNewPassword: (user: CognitoUser, newPassword: string, onSuccess: () => void) =>
        dispatch(completeNewPassword({ user, newPassword, onSuccess })),
      loadCurrentSession: () => dispatch(loadCurrentSession()),
      forgotPassword: (email: string, onSuccess: () => void) =>
        dispatch(forgotPassword({ email, onSuccess })),
      forgotPasswordSubmit: (
        email: string,
        code: string,
        newPassword: string,
        onSuccess: () => void,
      ) => dispatch(forgotPasswordSubmit({ email, code, newPassword, onSuccess })),
      resetToInitialState: () => dispatch(authActions.resetToInitialState()),
      resetError: () => dispatch(authActions.resetError()),
      resetAfterCompletingNewPassword: () =>
        dispatch(authActions.resetAfterCompletingNewPassword()),
      clearCacheCredentials: () => dispatch(authActions.clearCacheCredentials()),
    }),
    [dispatch],
  );
};
