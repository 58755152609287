import deLocale from 'date-fns/locale/de';
import { format, OptionsWithTZ, utcToZonedTime } from 'date-fns-tz';

const options: OptionsWithTZ = {
  locale: deLocale,
  timeZone: 'Europe/Berlin',
};

export const zoneDate = (date: Date, timeZone = 'Europe/Berlin'): Date =>
  utcToZonedTime(date, timeZone);

export const formatMonthStr = (date: Date): string =>
  format(zoneDate(date), 'MMMM yyyy', { ...options });

export const formatDateStr = (date: Date): string =>
  format(zoneDate(date), 'dd.MM.yyyy', { ...options });

export const formatUsDateStr = (date: Date): string =>
  format(zoneDate(date), 'yyyy-MM-dd', { ...options });

export const formatWeekdayDateStr = (date: Date): string =>
  format(zoneDate(date), 'EEEE, dd.MM.yyyy', { ...options });

export const formatTimeDateStr = (date: Date): string =>
  format(zoneDate(date), 'dd.MM.yyyy HH:mm', { ...options });

export const formatWeekdayTimeDateStr = (date: Date): string =>
  format(zoneDate(date), 'EEEE, dd.MM.yyyy HH:mm', { ...options });

export const formatDayStr = (date: Date): string => format(zoneDate(date), 'dd.MM', { ...options });
