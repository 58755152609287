import { useMemo } from 'react';

import { listUsers } from '../../store/user-list/thunks';
import { useAppDispatch } from '../store';

export const useUserListActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      listUsers: () => dispatch(listUsers()),
    }),
    [dispatch],
  );
};
