import { MutableRefObject, useCallback, useRef, useState } from 'react';

export const usePopover = (): {
  anchorRef: MutableRefObject<HTMLElement | null>;
  handleClose: () => void;
  handleOpen: () => void;
  handleToggle: () => void;
  open: boolean;
} => {
  const anchorRef = useRef<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);
  return {
    anchorRef,
    handleClose,
    handleOpen,
    handleToggle,
    open,
  };
};
