import { alpha } from '@mui/system/colorManipulator';

import { CustomPaletteOptions } from '../../../models/mui/custom-palette-options';
import { error, info, neutral, success, warning } from '../colors';

export const createDarkPalette = (): CustomPaletteOptions => {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: 'rgba(243, 244, 246, 0.04)',
      selected: alpha(neutral[100], 0.12),
    },
    background: {
      default: '#0E1320',
      paper: neutral[900],
    },
    divider: '#2D3748',
    secondaryDivider: {
      main: 'rgb(45, 55, 72)',
    },
    error,
    info,
    mode: 'dark',
    primary: {
      light: '#EBEEFE',
      main: '#6366F1',
      dark: '#4338CA',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#F3F4F6',
      main: '#6C737F',
      dark: '#384250',
      contrastText: '#FFFFFF',
    },
    success,
    text: {
      primary: '#EDF2F7',
      secondary: '#A0AEC0',
      disabled: 'rgba(255, 255, 255, 0.48)',
    },
    warning,
  };
};
