import { useLocation } from 'react-router-dom';

import { AuthUser } from '../../models/auth/auth-user';
import { useAppSelector } from '../store';

export const useRouteGuard = (): {
  isLoggedIn: boolean;
  user?: AuthUser;
  pathName: string;
} => {
  const { authUser } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();

  return { isLoggedIn: !!authUser, user: authUser, pathName: pathname };
};
