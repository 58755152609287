import { common } from '@mui/material/colors';
import { alpha } from '@mui/system/colorManipulator';

import { CustomPaletteOptions } from '../../../models/mui/custom-palette-options';
import { error, info, neutral, success, warning } from '../colors';

export const createLightPalette = (): CustomPaletteOptions => {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    divider: '#F2F4F7',
    secondaryDivider: {
      main: 'rgba(253, 253, 254, 1)',
    },
    error,
    info,
    mode: 'light',
    primary: {
      light: '#EBEEFE',
      main: '#6366F1',
      dark: '#4338CA',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#F3F4F6',
      main: '#6C737F',
      dark: '#384250',
      contrastText: '#FFFFFF',
    },
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
};
