import { PaletteMode } from '@mui/material';

export const getThemeInStorage = (): PaletteMode | undefined => {
  return (localStorage.getItem('theme') as PaletteMode) ?? undefined;
};

export const setThemeInStorage = (themeMode: PaletteMode): void => {
  localStorage.setItem('theme', themeMode);
};

export const getMiniSidebarStatusInStorage = (): boolean | undefined => {
  const isMiniSidebar = localStorage.getItem('isMiniSidebar');

  if (isMiniSidebar === 'true') {
    return true;
  }
  if (isMiniSidebar === 'false') {
    return false;
  }
  return undefined;
};

export const setMiniSidebarStatusInStorage = (status: boolean): void => {
  localStorage.setItem('isMiniSidebar', `${status}`);
};

const getUserLabelsStorageKey = (userId: string): string => {
  return `User-${userId}-Labels`;
};

export const setUserLabelsInStorage = (userId: string, labels: string[]): void => {
  const userLabelsJSON = JSON.stringify(labels);
  localStorage.setItem(getUserLabelsStorageKey(userId), userLabelsJSON);
};

export const getUserLabelsInStorage = (userId: string): string[] => {
  const userLabelsJSON = localStorage.getItem(getUserLabelsStorageKey(userId));
  if (!userLabelsJSON) return [];
  return JSON.parse(userLabelsJSON);
};

export const setEstimationTaskStepInStorage = (status: string): void => {
  localStorage.setItem('estimationTaskStep', `${status}`);
};

export const getEstimationTaskStepInStorage = (): string | null => {
  return localStorage.getItem('estimationTaskStep');
};
