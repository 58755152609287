import { createSlice } from '@reduxjs/toolkit';

import { CompleteNewPasswordError } from '../../models/errors/auth/complete-new-password-error';
import { ConfirmSignUpError } from '../../models/errors/auth/confirm-sign-up-error';
import { ForgotPasswordError } from '../../models/errors/auth/forgot-password-error';
import { ForgotPasswordSubmitError } from '../../models/errors/auth/forgot-password-submit-error';
import { ResendSignUpCodeError } from '../../models/errors/auth/resend-sign-up-code-error';
import { SignInError } from '../../models/errors/auth/sign-in-error';
import { SignOutError } from '../../models/errors/auth/sign-out-error';
import { SignUpError } from '../../models/errors/auth/sign-up-error';
import { AuthState } from './auth-state';
import {
  completeNewPassword,
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
  loadCurrentSession,
  resendSignUpCode,
  signIn,
  signOut,
  signUp,
} from './thunks';

const authInitialState: AuthState = {
  loading: false,
  newPasswordRequiredScreen: false,
  showConfirmationScreen: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    resetToInitialState: (state: AuthState) => {
      state.loading = false;
      state.errorMsg = undefined;
      state.errorCode = undefined;
      state.authUser = undefined;
      state.cognitoUser = undefined;
      state.newPasswordRequiredScreen = false;
      state.showConfirmationScreen = false;
      state.cacheCredentials = undefined;
    },
    resetError: (state: AuthState) => {
      state.errorMsg = undefined;
      state.errorCode = undefined;
    },
    resetAfterCompletingNewPassword: (state: AuthState) => {
      state.newPasswordRequiredScreen = false;
      state.cognitoUser = undefined;
    },
    clearCacheCredentials: (state: AuthState) => {
      state.cacheCredentials = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
      state.errorCode = undefined;
      state.cacheCredentials = undefined;
    });
    builder.addCase(signUp.fulfilled, (state: AuthState, { meta }) => {
      state.loading = false;
      state.showConfirmationScreen = true;
      state.cacheCredentials = {
        email: meta.arg.formValues.email,
      };
    });
    builder.addCase(signUp.rejected, (state: AuthState, { error }) => {
      const appError = new SignUpError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
      state.errorCode = appError.code;
    });
    builder.addCase(confirmSignUp.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
      state.errorCode = undefined;
    });
    builder.addCase(confirmSignUp.fulfilled, (state: AuthState) => {
      state.loading = false;
      state.showConfirmationScreen = false;
    });
    builder.addCase(confirmSignUp.rejected, (state: AuthState, { error }) => {
      const appError = new ConfirmSignUpError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(resendSignUpCode.pending, (state: AuthState) => {
      state.loading = true;
      state.errorCode = undefined;
      state.errorMsg = undefined;
    });
    builder.addCase(resendSignUpCode.fulfilled, (state: AuthState) => {
      state.loading = false;
    });
    builder.addCase(resendSignUpCode.rejected, (state: AuthState, { error }) => {
      const appError = new ResendSignUpCodeError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(signIn.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
      state.errorCode = undefined;
    });
    builder.addCase(signIn.fulfilled, (state: AuthState, { payload }) => {
      if (payload?.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        state.cognitoUser = payload.cognitoUser;
        state.newPasswordRequiredScreen = true;
      }
      state.authUser = payload.authUser;
      state.loading = false;
    });
    builder.addCase(signIn.rejected, (state: AuthState, { error, meta }) => {
      const appError = new SignInError(error);
      state.loading = false;
      if (appError.code === 'UserNotConfirmedException') {
        state.showConfirmationScreen = true;
        state.cacheCredentials = { email: meta.arg.email };
      } else {
        state.errorCode = appError.code;
        state.errorMsg = appError.appMessage;
      }
    });
    builder.addCase(signOut.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
      state.errorCode = undefined;
    });
    builder.addCase(signOut.fulfilled, (state: AuthState) => {
      state.loading = false;
    });
    builder.addCase(signOut.rejected, (state: AuthState, { error }) => {
      const appError = new SignOutError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(completeNewPassword.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(completeNewPassword.fulfilled, (state: AuthState) => {
      state.loading = false;
    });
    builder.addCase(completeNewPassword.rejected, (state: AuthState, { error }) => {
      const appError = new CompleteNewPasswordError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(forgotPassword.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
      state.errorCode = undefined;
    });
    builder.addCase(forgotPassword.fulfilled, (state: AuthState) => {
      state.loading = false;
    });
    builder.addCase(forgotPassword.rejected, (state: AuthState, { error }) => {
      const appError = new ForgotPasswordError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(forgotPasswordSubmit.pending, (state: AuthState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(forgotPasswordSubmit.fulfilled, (state: AuthState) => {
      state.loading = false;
    });
    builder.addCase(forgotPasswordSubmit.rejected, (state: AuthState, { error }) => {
      const appError = new ForgotPasswordSubmitError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(loadCurrentSession.fulfilled, (state: AuthState, { payload }) => {
      state.authUser = payload;
    });
    builder.addCase(loadCurrentSession.rejected, (state: AuthState) => {
      state.authUser = undefined;
    });
  },
});

export const authActions = authSlice.actions;
