import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '../app-error';

export class SignUpError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.sign-up-error');

    if (error?.code === 'UsernameExistsException') {
      this.appMessage = 'errors.auth.sign-up-username-exists';
    }
  }
}
