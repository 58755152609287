import { EstimationRun, RunGroup, RunItem } from '@estimation-tool/shared';

import {
  EstimationRunEntity,
  NormalizedEstimationRun,
  RunGroupsEntity,
  RunItemsEntity,
} from '../../models/estimation-runs';

export const normalizeRunGroups = (runGroups: RunGroup[]): RunGroupsEntity => {
  const byId = runGroups.reduce(
    (acc, runGroup) => {
      const groupId = `group-${runGroup.position}`;
      acc[groupId] = {
        id: groupId,
        name: runGroup.name,
        description: runGroup.description,
        position: runGroup.position,
        color: runGroup.color,
        items: runGroup.items.map((item) => item.id),
      };
      return acc;
    },
    {} as RunGroupsEntity['byId'],
  );

  const allIds = runGroups.map((runGroup) => `group-${runGroup.position}`);

  return { byId, allIds };
};

export const normalizeRunItems = (runItems: RunItem[]): RunItemsEntity => {
  const byId = runItems.reduce(
    (acc, runItem) => {
      acc[runItem.id] = runItem;
      return acc;
    },
    {} as RunItemsEntity['byId'],
  );

  const allIds = runItems.map((runItem) => runItem.id);

  return { byId, allIds };
};

export const normalizeEstimationRun = (estimationRun: EstimationRun): NormalizedEstimationRun => {
  const {
    id,
    name,
    owner,
    shortDescription,
    description,
    createdAt,
    updatedAt,
    status,
    labels,
    groups,
  } = estimationRun;

  const metadata: EstimationRunEntity = {
    id,
    name,
    owner,
    shortDescription,
    description,
    createdAt,
    updatedAt,
    status,
    labels,
  };

  return {
    metadata,
    groups: normalizeRunGroups(groups),
    items: normalizeRunItems(groups.flatMap((group) => group.items)),
  };
};
