import {
  AssigneeFinalizationInfo,
  EstimationTask,
  EstimationTaskFinalization,
  EstimationTaskFinalizationInputProperty,
  EstimationTaskFinalizationUpdateInput,
  EstimationTaskInput,
  EstimationTaskStatus,
  ExcelParametersInput,
  UpdateEstimationTaskInput,
  UpdateEstimationTaskResponse,
} from '@estimation-tool/shared';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { EstimationTaskListItem } from '../../../models/estimation-tasks/estimation-task-list-item';
import { performGraphqlOperation } from '../../../utils/graphql';

export class EstimationTasksService {
  public async listUserEstimationTasks(): Promise<EstimationTaskListItem[]> {
    const { getDataFromUser } = await performGraphqlOperation<{
      getDataFromUser: {
        estimationTasks: EstimationTaskListItem[];
      };
    }>(queries.listUserEstimationTasks);

    return getDataFromUser.estimationTasks;
  }

  public async getEstimationTaskDetails(id: string): Promise<EstimationTask> {
    const { getEstimationTaskDetails } = await performGraphqlOperation<{
      getEstimationTaskDetails: EstimationTask;
    }>(queries.getEstimationTaskDetails, { id });

    return getEstimationTaskDetails;
  }

  public async deleteEstimationTask(id: string): Promise<string> {
    const { deleteEstimationTask } = await performGraphqlOperation<{
      deleteEstimationTask: string;
    }>(mutations.deleteEstimationTask, { id });

    return deleteEstimationTask;
  }

  public async exportEstimationTask(
    id: string,
    excelParameters: ExcelParametersInput,
  ): Promise<string> {
    const { exportEstimationTask } = await performGraphqlOperation<{
      exportEstimationTask: string;
    }>(mutations.exportEstimationTask, { id, excelParameters });

    return exportEstimationTask;
  }

  public async createEstimationTask(
    newEstimationTask: EstimationTaskInput,
  ): Promise<EstimationTask> {
    const { createEstimationTask } = await performGraphqlOperation<{
      createEstimationTask: EstimationTask;
    }>(mutations.createEstimationTask, { newEstimationTask });
    return createEstimationTask;
  }

  public async updateEstimationTask(
    updatedEstimationTask: UpdateEstimationTaskInput,
  ): Promise<EstimationTask> {
    const { updateEstimationTask } = await performGraphqlOperation<{
      updateEstimationTask: EstimationTask;
    }>(mutations.updateEstimationTask, {
      updatedEstimationTask,
    });
    return updateEstimationTask;
  }

  public async startEstimationTask(startingEstimationTask: string): Promise<EstimationTask> {
    const { startEstimationTask } = await performGraphqlOperation<{
      startEstimationTask: EstimationTask;
    }>(mutations.startEstimationTask, { startingEstimationTask });
    return startEstimationTask;
  }

  public async completeEstimationTaskEvaluation(id: string): Promise<UpdateEstimationTaskResponse> {
    await this.updateEstimationTaskProperty({
      taskId: id,
      status: EstimationTaskStatus.IN_FINALIZATION,
    });
    return { status: EstimationTaskStatus.IN_FINALIZATION };
  }

  public async endEstimationRun(id: string): Promise<AssigneeFinalizationInfo[]> {
    const { endEstimationRun } = await performGraphqlOperation<{
      endEstimationRun: AssigneeFinalizationInfo[];
    }>(mutations.endEstimationRun, { id });

    return endEstimationRun;
  }

  public async updateEstimationTaskProperty(
    updatedEstimationTask: EstimationTaskFinalizationUpdateInput,
  ): Promise<UpdateEstimationTaskResponse> {
    const { updateEstimationTask } = await performGraphqlOperation<{
      updateEstimationTask: EstimationTask;
    }>(mutations.updateEstimationTaskProperty, {
      updatedEstimationTask,
    });
    return updateEstimationTask;
  }

  public async getEstimationTaskFinalization(id: string): Promise<EstimationTaskFinalization> {
    const { getEstimationTaskFinalization } = await performGraphqlOperation<{
      getEstimationTaskFinalization: EstimationTaskFinalization;
    }>(queries.getEstimationTaskFinalization, { id });

    return getEstimationTaskFinalization;
  }

  // TODO @Laurin Unclean? Tatiana fragen
  public async completeEstimationTaskFinalization(
    estimationTaskFinalization: EstimationTaskFinalization,
  ): Promise<UpdateEstimationTaskResponse> {
    estimationTaskFinalization.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (item.estimationFinalValue === null) {
          this.updateEstimationTaskProperty({
            taskId: estimationTaskFinalization.id,
            itemId: item.id,
            propertyName: EstimationTaskFinalizationInputProperty.ESTIMATION_FINAL_VALUE,
            value: item.mean.toString(),
          });
        }
      });
    });
    await this.updateEstimationTaskProperty({
      taskId: estimationTaskFinalization.id,
      status: EstimationTaskStatus.COMPLETED,
    });

    return { status: EstimationTaskStatus.COMPLETED };
  }
}
