import './config/i18next';
import 'symbol-observable';

import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';

import AuthHub from './components/auth-hub';
import configureAmplify from './config/amplify/configure-amplify';
import { useThemeActions } from './hooks';
import PageLoadingIndicator from './layouts/components/page-loading-indicator';
import Routes from './routes';

configureAmplify();

function App() {
  const { theme } = useThemeActions();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageLoadingIndicator />
      <AuthHub />
      <Box sx={{ height: '100%' }}>
        <Routes />
      </Box>
    </ThemeProvider>
  );
}

export default App;
