import { SerializedError } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { ContactEmails } from '../../../config/global-values/app-values';
import { AppError } from '../app-error';

export class ForgotPasswordError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.forgot-password-error');

    if (error?.code === 'LimitExceededException' || error?.code === 'TooManyRequestsException') {
      this.appMessage = 'errors.auth.account-locked';
    }

    if (error?.code === 'UserNotFoundException') {
      this.appMessage = 'errors.auth.no-account-found';
    }

    if (error?.code === 'NotAuthorizedException' || error?.code === 'InvalidParameterException') {
      this.appMessage = t('errors.auth.account-not-activated', { email: ContactEmails.SERVICE });
    }
  }
}
