import { v4 as uuid } from 'uuid';

import { estimationGroupColors, groupColors } from '../../config/theme/app-colors';
import { FrontendTaskGroup } from '../../models/estimation-tasks/frontend-task-group';
import { FrontendTaskItem } from '../../models/estimation-tasks/frontend-task-item';

export const getRandomGroupColor = (): string => {
  const randomIndex = Math.floor(Math.random() * estimationGroupColors.length);
  return estimationGroupColors[randomIndex];
};

/**
 * Generates a username shortcut from a given name.
 *
 * The function takes a name and extracts the initials based on the following rules:
 * - If a last name is provided, it uses the first letter of the first name and the first letter of the last name.
 * - If no last name is provided, it uses the first letter of the first name and the second letter of the first name.
 *
 * @param {string} name - The full name to generate the username from.
 * @returns {string} The generated username shortcut.
 */
export const generateUsernamesShortcut = (name: string): string => {
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.length > 0 ? lastName.charAt(0) : firstName.charAt(1);

  return `${firstInitial}${lastInitial}`;
};

/**
 * Creates a new task item.
 *
 * @returns {FrontendTaskItem} The created task item.
 */
export const createNewItem = (): FrontendTaskItem => ({
  dragId: uuid(),
  name: '',
});

/**
 * Creates a new task group with a default item.
 *
 * @returns {FrontendTaskGroup} The created task group.
 */
export const createNewGroup = (): FrontendTaskGroup => ({
  dragId: uuid(),
  name: '',
  color: getRandomGroupColor(),
  items: [createNewItem()],
});

/**
 * Extracts IDs from an array of task items.
 *
 * @param {FrontendTaskItem[]} items - The task items from which to extract IDs.
 * @returns {string[]} An array of extracted item IDs.
 */
export const extractIdsFromTaskItems = (items: FrontendTaskItem[]): string[] => {
  return items.reduce((idsCollection: string[], item) => {
    if (item.id) {
      idsCollection.push(item.id);
    }
    return idsCollection;
  }, []);
};

export const getColorOptions = (
  color: string,
): {
  color: string;
  light: string;
  main: string;
  dark: string;
  borderLight: string;
} => {
  const selectedColor = groupColors.find((groupColor) => groupColor.color === color);
  // Handle the case when the color is not found,return a default value.
  return selectedColor ?? groupColors[0];
};
