import { User } from '@estimation-tool/shared';

import * as queries from '../graphql/queries';
import { performGraphqlOperation } from '../utils/graphql';

export class UserListService {
  public async listUsers(): Promise<User[]> {
    const { listUsers } = await performGraphqlOperation<{ listUsers: User[] }>(queries.listUsers);
    return listUsers;
  }
}
