export var EstimationRunStatus;
(function (EstimationRunStatus) {
    EstimationRunStatus["NEW"] = "NEW";
    EstimationRunStatus["IN_PROGRESS"] = "IN_PROGRESS";
    EstimationRunStatus["COMPLETED"] = "COMPLETED";
    EstimationRunStatus["EXPIRED"] = "EXPIRED";
})(EstimationRunStatus || (EstimationRunStatus = {}));
export var EstimationRunInputProperty;
(function (EstimationRunInputProperty) {
    EstimationRunInputProperty["VALUE"] = "estimationValue";
    EstimationRunInputProperty["MIN"] = "estimationValueMin";
    EstimationRunInputProperty["MAX"] = "estimationValueMax";
    EstimationRunInputProperty["COMMENT"] = "comment";
})(EstimationRunInputProperty || (EstimationRunInputProperty = {}));
