import {
  alpha,
  backdropClasses,
  Components,
  filledInputClasses,
  outlinedInputClasses,
  PaletteOptions,
  paperClasses,
  tableCellClasses,
} from '@mui/material';
import { common } from '@mui/material/colors';

import { neutral } from '../colors';

export const createDarkComponents = (palette: PaletteOptions): Components => ({
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        [`&:not(.${backdropClasses.invisible})`]: {
          backgroundColor: alpha(common.black, 0.5),
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        cursor: 'default',
        backgroundColor: 'rgba(243, 244, 246, 0.15)',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          backgroundImage: 'none',
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '#nprogress .bar': {
        backgroundColor: palette?.primary,
      },
      '.slick-dots li button': {
        '&:before': {
          fontSize: 10,
          color: palette?.primary,
        },
      },
      '.slick-dots li.slick-active button': {
        '&:before': {
          color: palette?.primary,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
        textTransform: 'none',
      },
      sizeSmall: {
        padding: '6px 16px',
      },
      sizeMedium: {
        padding: '8px 20px',
      },
      sizeLarge: {
        padding: '11px 24px',
      },
      textSizeSmall: {
        padding: '7px 12px',
      },
      textSizeMedium: {
        padding: '9px 16px',
      },
      textSizeLarge: {
        padding: '12px 16px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: '0px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: '0px',
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        [`&.${paperClasses.elevation1}`]: {
          boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6',
      },
      subheaderTypographyProps: {
        variant: 'body2',
      },
    },
    styleOverrides: {
      root: {
        padding: '32px 24px 16px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&::placeholder': {
          color: palette?.text?.secondary,
          opacity: 1,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: palette?.action?.hover,
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.divider,
          },
        },
        [`&.${outlinedInputClasses.focused}`]: {
          backgroundColor: 'transparent',
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.primary,
            borderWidth: '2px',
          },
        },
        [`&.${filledInputClasses.error}`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.error,
            borderWidth: '2px',
          },
        },
      },
      notchedOutline: {
        borderColor: palette.divider,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: neutral[500],
      },
      track: {
        backgroundColor: neutral[400],
        opacity: 1,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderColor: palette.divider,
        '&:hover': {
          backgroundColor: palette?.action?.hover,
        },
        [`&.${filledInputClasses.disabled}`]: {
          backgroundColor: 'transparent',
        },
        [`&.${filledInputClasses.focused}`]: {
          backgroundColor: 'transparent',
          borderColor: palette?.primary,
          boxShadow: `${palette?.primary} 0 0 0 2px`,
        },
        [`&.${filledInputClasses.error}`]: {
          borderColor: palette?.error,
          boxShadow: `${palette?.error} 0 0 0 2px`,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
        '&::placeholder': {
          color: palette?.text?.secondary,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${palette.background?.paper} inset`,
            WebkitTextFillColor: 'default',
          },
        },
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        maxWidth: '400px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&::before': {
          content: 'none',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 0,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backdropFilter: 'blur(6px)',
        background: 'rgb(48 52 64)',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: palette.text?.primary,
        borderBottomColor: palette.divider,
        padding: '6px 16px',
        [`&.${tableCellClasses.footer}`]: {
          borderColor: 'transparent',
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
        [`& .${tableCellClasses.root}`]: {
          backgroundColor: 'rgb(28, 37, 54)',
          color: 'rgb(157, 164, 174)',
          borderBottom: 'none',
        },
        [`& .${tableCellClasses.paddingCheckbox}`]: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        boxShadow: 'inset 0 0 0 2.4px #5356c74d',
        borderRadius: '50%',
      },
    },
  },
});
