import {
  AssigneeFinalizationInfo,
  EstimationTask,
  EstimationTaskFinalization,
  EstimationTaskFinalizationUpdateInput,
  EstimationTaskInput,
  ExcelParametersInput,
  UpdateEstimationTaskInput,
  UpdateEstimationTaskResponse,
} from '@estimation-tool/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { EstimationTasksService } from '../../features/estimation-tasks/services';
import { EstimationTaskListItem } from '../../models/estimation-tasks/estimation-task-list-item';
// eslint-disable-next-line import/no-cycle
import { estimationTasksActions } from './slice';

const estimationTasksService = new EstimationTasksService();

export const listUserEstimationTasks = createAsyncThunk(
  'estimationTasks/listUserEstimationTasks',
  async (): Promise<EstimationTaskListItem[]> => {
    return await estimationTasksService.listUserEstimationTasks();
  },
);

export const deleteEstimationTask = createAsyncThunk(
  'estimationTasks/deleteEstimationTask',
  async (props: { taskId: string; onSuccess?: () => void }): Promise<string> => {
    await estimationTasksService.deleteEstimationTask(props.taskId);
    if (props.onSuccess) {
      props.onSuccess();
    }
    return props.taskId;
  },
);

export const exportEstimationTask = createAsyncThunk(
  'estimationTasks/exportEstimationTask',
  async (props: { taskId: string; excelParameters: ExcelParametersInput }): Promise<string> => {
    return await estimationTasksService.exportEstimationTask(props.taskId, props.excelParameters);
  },
);

export const createNewEstimationTask = createAsyncThunk(
  'estimationTasks/createEstimationTask',
  async (props: {
    newEstimationTask: EstimationTaskInput;
    onSuccess: (id: string) => void;
  }): Promise<EstimationTask> => {
    const res = await estimationTasksService.createEstimationTask(props.newEstimationTask);
    props.onSuccess(res.id);
    return res;
  },
);

export const updateEstimationTask = createAsyncThunk(
  'estimationTasks/updateEstimationTask',
  async (props: {
    updatedEstimationTask: UpdateEstimationTaskInput;
    onSuccess?: () => void;
  }): Promise<EstimationTask> => {
    const res = await estimationTasksService.updateEstimationTask(props.updatedEstimationTask);
    if (props.onSuccess) {
      props.onSuccess();
    }
    return res;
  },
);

export const getEstimationTaskDetails = createAsyncThunk(
  'estimationTasks/getEstimationTaskDetails',
  async (props: { id: string }): Promise<EstimationTask> => {
    return await estimationTasksService.getEstimationTaskDetails(props.id);
  },
);

export const startEstimationTask = createAsyncThunk(
  'estimationRuns/startEstimationTask',
  async (props: { taskId: string; onSuccess?: () => void }): Promise<EstimationTask> => {
    const res = await estimationTasksService.startEstimationTask(props.taskId);
    if (props.onSuccess) {
      props.onSuccess();
    }
    return res;
  },
);
export const completeEstimationTaskEvaluation = createAsyncThunk(
  'estimationTasks/completeEstimationTaskEvaluation',
  async (props: {
    taskId: string;
    onSuccess?: () => void;
  }): Promise<UpdateEstimationTaskResponse> => {
    const res = await estimationTasksService.completeEstimationTaskEvaluation(props.taskId);
    if (props.onSuccess) {
      props.onSuccess();
    }
    return res;
  },
);

export const endEstimationRun = createAsyncThunk(
  'estimationTasks/endEstimationRun',
  async (props: {
    taskId: string;
    onSuccess?: () => void;
  }): Promise<AssigneeFinalizationInfo[]> => {
    const res = await estimationTasksService.endEstimationRun(props.taskId);
    if (props.onSuccess) {
      props.onSuccess();
    }
    return res;
  },
);

export const getEstimationTaskFinalization = createAsyncThunk(
  'estimationTasks/getEstimationTaskFinalization',
  async (props: { id: string }): Promise<EstimationTaskFinalization> => {
    return await estimationTasksService.getEstimationTaskFinalization(props.id);
  },
);

export const updateEstimationTaskProperty = createAsyncThunk(
  'estimationTasks/updateEstimationTaskProperty',
  async (
    props: {
      updatedEstimationTask: EstimationTaskFinalizationUpdateInput;
    },
    { dispatch },
  ): Promise<UpdateEstimationTaskResponse> => {
    const { propertyName, itemId, value } = props.updatedEstimationTask;

    const returnResult = await estimationTasksService.updateEstimationTaskProperty(
      props.updatedEstimationTask,
    );

    if (propertyName && itemId && (value || value === '')) {
      dispatch(
        estimationTasksActions.updateFinalizationItem({
          field: propertyName,
          itemId,
          value,
        }),
      );
    }

    return returnResult;
  },
);

export const completeEstimationTaskFinalization = createAsyncThunk(
  'estimationTasks/completeEstimationTaskFinalization',
  async (props: {
    finalizationTask: EstimationTaskFinalization;
    onSuccess?: () => void;
  }): Promise<UpdateEstimationTaskResponse> => {
    const res = await estimationTasksService.completeEstimationTaskFinalization(
      props.finalizationTask,
    );
    if (props.onSuccess) {
      props.onSuccess();
    }
    return res;
  },
);
