import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '../app-error';

export class ForgotPasswordSubmitError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.forgot-password-error');

    if (error?.code === 'LimitExceededException' || error?.code === 'TooManyRequestsException') {
      this.appMessage = 'errors.auth.account-locked';
    }

    if (error?.code === 'CodeMismatchException') {
      this.appMessage = 'errors.auth.code-not-valid';
    }

    if (error?.code === 'ExpiredCodeException') {
      this.appMessage = 'errors.auth.code-expired';
    }
  }
}
