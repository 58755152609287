import { Amplify, Auth } from 'aws-amplify';

const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_USER_POOL_REGION,
      userPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    },
    API: {
      aws_appsync_graphqlEndpoint: process.env.REACT_APP_API_ENDPOINT,
      aws_appsync_region: process.env.REACT_APP_API_REGION,
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      graphql_headers: async () => {
        const session = await Auth.currentSession();
        return { Authorization: session.getIdToken().getJwtToken() };
      },
    },
  });
};

export default configureAmplify;
