import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '../app-error';

export class ResendSignUpCodeError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.could-not-send-code');
    if (error?.code === ('LimitExceededException' || 'TooManyRequestsException')) {
      this.appMessage = 'errors.auth.account-locked';
    }
  }
}
