import { PaletteMode, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { createMaterialTheme } from '../../config/theme';
import { themeActions } from '../../store/theme';
import {
  getMiniSidebarStatusInStorage,
  getThemeInStorage,
  setMiniSidebarStatusInStorage,
  setThemeInStorage,
} from '../../utils';
import { useAppDispatch, useAppSelector } from '../store';

export const useIsDarkTheme = (): boolean => {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
};

export const useThemeActions = () => {
  const dispatch = useAppDispatch();
  const { mode, isMiniSidebar } = useAppSelector((root) => root.theme);

  // Enable dark mode automatically by checking for the user's preference in their OS or browser settings.
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    const getThemeMode = (): PaletteMode => {
      let themeMode = getThemeInStorage();
      if (!themeMode) {
        // If no theme mode is saved, use the user's preference
        themeMode = prefersDarkMode ? 'dark' : 'light';
        setThemeInStorage(themeMode);
      }
      return themeMode;
    };

    dispatch(themeActions.setTheme(getThemeMode()));
  }, [dispatch, prefersDarkMode]);

  const toggleTheme = () => {
    const newThemeMode = mode === 'light' ? 'dark' : 'light';
    dispatch(themeActions.setTheme(newThemeMode));
    setThemeInStorage(newThemeMode);
  };

  const theme = useMemo(() => createMaterialTheme(mode), [mode]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newThemeMode = getThemeInStorage();
      if (newThemeMode && newThemeMode !== mode) {
        dispatch(themeActions.setTheme(newThemeMode));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, mode]);

  // MiniSidebar

  useEffect(() => {
    const miniSidebarStatus = getMiniSidebarStatusInStorage();
    if (miniSidebarStatus !== undefined) {
      dispatch(themeActions.setMiniSidebarStatus(miniSidebarStatus));
    } else {
      setMiniSidebarStatusInStorage(true);
    }
  }, [dispatch]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newMiniSidebarStatus = getMiniSidebarStatusInStorage();
      if (newMiniSidebarStatus !== isMiniSidebar) {
        dispatch(themeActions.setMiniSidebarStatus(newMiniSidebarStatus));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, isMiniSidebar]);

  const changeMiniSidebarStatus = (status: boolean) => {
    dispatch(themeActions.setMiniSidebarStatus(status));
    setMiniSidebarStatusInStorage(status);
  };

  return { theme, toggleTheme, changeMiniSidebarStatus };
};
