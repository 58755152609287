import './loading-spinner.css';

import { Box } from '@mui/material';
import React from 'react';

const LoadingSpinner = () => {
  return (
    <Box sx={{ height: '100vh' }} display="flex" justifyContent="center" alignItems="center">
      <div className="loadingspinner">
        <div id="square1" />
        <div id="square2" />
        <div id="square3" />
        <div id="square4" />
        <div id="square5" />
      </div>
    </Box>
  );
};

export default LoadingSpinner;
