import { FrontendTaskGroup } from '../../models/estimation-tasks/frontend-task-group';

/**
 * Reorders items in a list after a drag and drop operation.
 *
 * @param list - The list of items to be reordered.
 * @param startIndex - The index of the item that is being dragged (source).
 * @param endIndex - The index where the dragged item should be dropped (destination).
 * @returns A new list with the items reordered based on the drag and drop action.
 */

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Reorders items within estimation groups based on drag-and-drop action.
 * Updates the state with the reordered groups.
 *
 * @param {FrontendTaskGroup[]} groups - The array of estimation task groups.
 * @param {number} itemSourceIndex - The index of the item being moved.
 * @param {number} itemDestinationIndex - The index where the item will be placed.
 * @param {string} sourceDroppableId - The ID of the source group.
 * @param {string} destinationDroppableId - The ID of the destination group.
 * @returns {FrontendTaskGroup[]} Updated array of estimation task groups.
 */
export const reorderItemsWithinGroups = (
  groups: FrontendTaskGroup[],
  itemSourceIndex: number,
  itemDestinationIndex: number,
  sourceDroppableId: string,
  destinationDroppableId: string,
): FrontendTaskGroup[] => {
  const getSourceIndex = (id: string) => groups.findIndex((group) => group.dragId === id);

  const { items: sourceItems } = groups[getSourceIndex(sourceDroppableId)];
  const { items: destinationItems } = groups[getSourceIndex(destinationDroppableId)];

  const [deletedItem] = sourceItems.splice(itemSourceIndex, 1);
  destinationItems.splice(itemDestinationIndex, 0, deletedItem);

  return groups.map((group) => {
    if (group.dragId === sourceDroppableId) {
      return { ...group, items: sourceItems };
    }
    if (group.dragId === destinationDroppableId) {
      return { ...group, items: destinationItems };
    }
    return group;
  });
};
