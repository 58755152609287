import { createSlice } from '@reduxjs/toolkit';

import { ListUsersError } from '../../models/errors/user-list/list-users-error';
import { listUsers } from './thunks';
import { UserListState } from './user-list-state';

const initialState: UserListState = {
  loading: false,
  users: [],
};
export const userListSlice = createSlice({
  name: 'user-list',
  initialState,
  reducers: {
    resetToInitialState: (state: UserListState) => {
      state.errorMsg = undefined;
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listUsers.pending, (state: UserListState) => {
      state.loading = true;
    });
    builder.addCase(listUsers.fulfilled, (state: UserListState, { payload }) => {
      state.users = payload;
      state.loading = false;
    });
    builder.addCase(listUsers.rejected, (state: UserListState, { error }) => {
      const appError = new ListUsersError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
  },
});

export const userListActions = userListSlice.actions;
