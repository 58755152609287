import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { authSlice } from './auth';
import { estimationRunsSlice } from './estimation-run';
import { estimationTasksSlice } from './estimation-tasks';
import { themeSlice } from './theme';
import { userListSlice } from './user-list/slice';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  estimationTasks: estimationTasksSlice.reducer,
  estimationRuns: estimationRunsSlice.reducer,
  theme: themeSlice.reducer,
  userList: userListSlice.reducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
