import { Hub } from 'aws-amplify';
import { useEffect } from 'react';

import { useAuthActions } from '../../features/authentication/hooks';

const AuthHub = () => {
  const { loadCurrentSession } = useAuthActions();
  useEffect(() => {
    const updateUser = () => {
      loadCurrentSession();
    };
    /* start listening for messages */
    const hubListenerCancelToken = Hub.listen('auth', updateUser);
    updateUser();
    // stop listening for messages
    return () => hubListenerCancelToken();
  }, [loadCurrentSession]);
  return null;
};

export default AuthHub;
