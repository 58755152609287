// Blue
export const oxfordBlue = '#1C2536';
export const irisBlue = '#3C4CDB';

export const risdBlue = '#3C5CF8';

export const ghostWhite = '#F4F7FF';

// Purple
export const mediumSlateBlue = '#7856FF';

// Pink
export const cerise = '#E81E61';

// Green
export const seaGreen = '#219464';

export const gunmetal = '#2F3746';
export const cadetGray = '#9DA4AE';

export const estimationTaskDetailsTagsColors = [seaGreen, mediumSlateBlue, irisBlue];

export enum GroupPalette {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
}

export const estimationGroupColors = [
  GroupPalette.GREEN,
  GroupPalette.BLUE,
  GroupPalette.PURPLE,
  GroupPalette.YELLOW,
  GroupPalette.ORANGE,
];

export const groupColors = [
  {
    color: GroupPalette.GREEN,
    light: '#D3EEB9',
    main: '#4FEC8A',
    dark: '#23544E',
    borderLight: '#00B884',
  },
  {
    color: GroupPalette.BLUE,
    light: '#C4DCF0',
    main: '#5FB1F1',
    dark: '#1F4F73',
    borderLight: '#5577FF',
  },
  {
    color: GroupPalette.PURPLE,
    light: '#CBBCD9',
    main: '#8879F1',
    dark: '#453C5C',
    borderLight: '#7B68EE',
  },
  {
    color: GroupPalette.YELLOW,
    light: '#F3F3C2',
    main: '#F5ED98',
    dark: '#8A742D',
    borderLight: '#5577FF',
  },
  {
    color: GroupPalette.ORANGE,
    light: '#F0C7D0',
    main: '#EFA999',
    dark: '#733D30',
    borderLight: '#7B68EE',
  },
];

export const tagColors = {
  black: {
    light: '#000',
    dark: '#000',
  },
  blue: {
    light: irisBlue,
    dark: '#1F4F73',
  },
  purple: {
    light: mediumSlateBlue,
    dark: '#453C5C',
  },
  green: {
    light: seaGreen,
    dark: '#23544E',
  },
};

export const tableFooterColor = {
  dark: 'rgb(28, 37, 54)',
  light: 'rgb(248, 249, 250)',
};
