export var EstimationTaskStatus;
(function (EstimationTaskStatus) {
    EstimationTaskStatus["NEW"] = "NEW";
    EstimationTaskStatus["IN_PROGRESS"] = "IN_PROGRESS";
    EstimationTaskStatus["IN_FINALIZATION"] = "IN_FINALIZATION";
    EstimationTaskStatus["COMPLETED"] = "COMPLETED";
})(EstimationTaskStatus || (EstimationTaskStatus = {}));
export var EstimationTaskFinalizationInputProperty;
(function (EstimationTaskFinalizationInputProperty) {
    EstimationTaskFinalizationInputProperty["ESTIMATION_FINAL_VALUE"] = "estimationFinalValue";
    EstimationTaskFinalizationInputProperty["TASK_OWNER_COMMENT"] = "taskOwnerComment";
})(EstimationTaskFinalizationInputProperty || (EstimationTaskFinalizationInputProperty = {}));
