export const listUserEstimationRuns = /* GraphQL */ `
  query getDataFromUser {
    getDataFromUser {
      estimationRuns {
        id
        name
        shortDescription
        status
        createdAt
        owner {
          id
          name
        }
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query listUsers {
    listUsers {
      id
      name
    }
  }
`;

export const listUserEstimationTasks = /* GraphQL */ `
  query getDataFromUser {
    getDataFromUser {
      estimationTasks {
        id
        name
        shortDescription
        status
        createdAt
      }
    }
  }
`;

export const getEstimationTaskDetails = /* GraphQL */ `
  query getEstimationTaskDetails($id: String) {
    getEstimationTaskDetails(id: $id) {
      id
      createdAt
      description
      labels
      name
      shortDescription
      status
      updatedAt
      groups {
        description
        items {
          id
          description
          name
          position
        }
        name
        position
        color
      }
      assignees {
        id
        name
      }
    }
  }
`;

export const getEstimationRunDetails = /* GraphQL */ `
  query getEstimationRunDetails($id: String) {
    getEstimationRunDetails(id: $id) {
      createdAt
      description
      id
      groups {
        color
        description
        items {
          description
          estimationValue
          estimationValueMax
          estimationValueMin
          id
          name
          position
          comment
        }
        name
        position
      }
      labels
      name
      owner {
        id
        name
      }
      shortDescription
      status
      updatedAt
    }
  }
`;

export const getEstimationTaskEvaluation = /* GraphQL */ `
  query getEstimationTaskEvaluation($id: String) {
    getEstimationTaskEvaluation(id: $id) {
      id
      name
      shortDescription
      description
      createdAt
      updatedAt
      status
      labels
      evaluationSum
      assignees {
        id
        name
        status
        completedItems
      }
      groups {
        name
        description
        color
        position
        mean
        min
        max
        filledOutItems
        completedAssigneesCount
        items {
          id
          name
          description
          position
          mean
          min
          max
          estimationValueCount
          assigneeValues {
            id
            name
            comment
            value
          }
        }
      }
      itemsCount
    }
  }
`;
export const getEstimationTaskFinalization = /* GraphQL */ `
  query getEstimationTaskFinalization($id: String) {
    getEstimationTaskFinalization(id: $id) {
      groups {
        items {
          id
          name
          max
          mean
          min
          estimationFinalValue
          estimationValueCount
          description
          taskOwnerComment
          assigneeValues {
            id
            name
            comment
            value
          }
        }
        min
        mean
        max
        color
        description
        filledOutItems
        name
        position
      }
      createdAt
      evaluationSum
      description
      id
      itemsCount
      labels
      name
      updatedAt
      status
      shortDescription
      assignees {
        completedItems
        id
        name
        status
      }
    }
  }
`;
