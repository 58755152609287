import {
  EstimationTask,
  TaskGroup,
  TaskGroupInput,
  UpdateEstimationTaskInput,
} from '@estimation-tool/shared';

import { FrontendEstimationTask } from '../../models/estimation-tasks/frontend-estimation-task';
import { FrontendTaskGroup } from '../../models/estimation-tasks/frontend-task-group';
// TODO Test EstimationTask Mapper
/**
 * Assigns drag IDs to Estimation Groups and items for enabling drag and drop functionality.
 * Each group and item is assigned a unique drag ID based on its name and position / ID.
 *
 * @param groups - Original task groups.
 * @returns Task groups with drag and drop IDs.
 */
export const assignDragIdsToGroups = (groups: TaskGroup[]): FrontendTaskGroup[] => {
  return groups?.map((group) => ({
    ...group,
    dragId: `${group.name}-${group.position}`,
    items: group.items.map((item) => ({
      ...item,
      dragId: item.id,
    })),
  }));
};

/**
 * Maps backend EstimationTask to FrontendEstimationTask.
 * Assigns drag IDs to the groups within the task.
 *
 * @param estimationTask - Backend EstimationTask to be mapped.
 * @returns Mapped FrontendEstimationTask with drag IDs assigned.
 */
export const mapTaskToFrontendTask = (estimationTask: EstimationTask): FrontendEstimationTask => {
  return {
    ...estimationTask,
    groups: assignDragIdsToGroups(estimationTask.groups),
  };
};

export const mapFrontendGroupToUpdateGroup = (
  frontendTaskGroups: FrontendTaskGroup[],
): TaskGroupInput[] => {
  return (
    frontendTaskGroups
      ?.map((frontendTaskGroup, groupIndex) => ({
        name: frontendTaskGroup.name,
        description: frontendTaskGroup.description,
        position: groupIndex,
        color: frontendTaskGroup.color,
        items: frontendTaskGroup.items.map((item, itemIndex) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          itemPosition: itemIndex,
        })),
      }))
      // Groups with zero items will be removed
      .filter((group) => group.items.length !== 0)
  );
};

/**
 * Maps a frontend estimation task along with a list of deleted item IDs to an
 * UpdateEstimationTaskInput object. This mapped object can be used to send updates
 * to the backend for updating an estimation task.
 *
 * @param {FrontendEstimationTask} frontendEstimationTask - The frontend representation of the estimation task.
 * @param {string[]} deletedItemIds - An array of IDs of items that should be deleted.
 * @returns {UpdateEstimationTaskInput} The mapped object for updating the estimation task on the backend.
 */
export const mapFrontendTaskToUpdateInput = (
  frontendEstimationTask: FrontendEstimationTask,
  deletedItemIds: string[],
): UpdateEstimationTaskInput => {
  return {
    estimationTask: {
      id: frontendEstimationTask.id,
      name: frontendEstimationTask.name,
      shortDescription: frontendEstimationTask?.shortDescription,
      description: frontendEstimationTask?.description,
      status: frontendEstimationTask?.status,
      labels: frontendEstimationTask?.labels,
      assignees: frontendEstimationTask?.assignees,
      groups: mapFrontendGroupToUpdateGroup(frontendEstimationTask.groups),
    },
    deleteItems: deletedItemIds,
  };
};
