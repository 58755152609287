import { EstimationRunInputProperty } from '@estimation-tool/shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetEstimationRunDetailError } from '../../models/errors/estimation-runs/get-estimation-run-detail-error';
import { ListEstimationRunsError } from '../../models/errors/estimation-runs/list-estimation-runs-error';
import { UpdateEstimationRunError } from '../../models/errors/estimation-runs/update-estimation-run-error';
import { normalizeEstimationRun } from '../../utils/api-mapper/estimation-run-mapper';
import { UpdateEstimationItemValuePayload, UpdatingValueInGroupPayload } from './action-types';
import { EstimationRunState } from './estimation-run-state';
import { updateEstimationItemValue } from './reducers-logic';
// eslint-disable-next-line import/no-cycle
import {
  getEstimationRunDetails,
  listUserEstimationRuns,
  submitEstimationRun,
  updateEstimationRun,
} from './thunks';

const estimationRunsInitialState: EstimationRunState = {
  loading: false,
  estimationRunsList: [],
};

export const estimationRunsSlice = createSlice({
  name: 'estimation-runs',
  initialState: estimationRunsInitialState,
  reducers: {
    resetError: (state: EstimationRunState) => {
      state.errorMsg = undefined;
    },
    resetStateToInitial: (state: EstimationRunState) => {
      state.errorMsg = undefined;
      state.estimationRunsList = [];
      state.loading = false;
    },
    updateEstimationItemValue(
      state: EstimationRunState,
      { payload }: PayloadAction<UpdateEstimationItemValuePayload>,
    ) {
      updateEstimationItemValue(state, payload);
    },
    setUpdatingValueInGroup(
      state: EstimationRunState,
      { payload }: PayloadAction<UpdatingValueInGroupPayload>,
    ) {
      if (payload.propertyName === EstimationRunInputProperty.VALUE && payload.groupId) {
        state.updatingValueInGroup = payload.groupId;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listUserEstimationRuns.pending, (state: EstimationRunState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(listUserEstimationRuns.fulfilled, (state: EstimationRunState, { payload }) => {
      state.loading = false;
      state.estimationRunsList = payload;
    });
    builder.addCase(listUserEstimationRuns.rejected, (state: EstimationRunState, { error }) => {
      const appError = new ListEstimationRunsError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(getEstimationRunDetails.pending, (state: EstimationRunState) => {
      state.loading = true;
      state.estimationRunDetails = undefined;
      state.errorMsg = undefined;
    });
    builder.addCase(getEstimationRunDetails.fulfilled, (state: EstimationRunState, { payload }) => {
      state.loading = false;
      state.estimationRunDetails = normalizeEstimationRun(payload);
    });
    builder.addCase(getEstimationRunDetails.rejected, (state: EstimationRunState, { error }) => {
      const appError = new GetEstimationRunDetailError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(updateEstimationRun.pending, (state: EstimationRunState) => {});
    builder.addCase(updateEstimationRun.fulfilled, (state: EstimationRunState, { payload }) => {
      state.updatingValueInGroup = null;
      if (payload?.status && state.estimationRunDetails?.metadata) {
        state.estimationRunDetails.metadata.status = payload.status;
      }
    });
    builder.addCase(updateEstimationRun.rejected, (state: EstimationRunState, { error }) => {
      const appError = new UpdateEstimationRunError(error);
      state.updatingValueInGroup = null;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(submitEstimationRun.pending, (state: EstimationRunState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(submitEstimationRun.fulfilled, (state: EstimationRunState, { payload }) => {
      state.loading = false;
      if (payload?.status && state.estimationRunDetails?.metadata) {
        state.estimationRunDetails.metadata.status = payload.status;
      }
    });
    builder.addCase(submitEstimationRun.rejected, (state: EstimationRunState, { error }) => {
      const appError = new UpdateEstimationRunError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
  },
});

export const estimationRunsActions = estimationRunsSlice.actions;
