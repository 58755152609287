import {
  EstimationRun,
  EstimationRunInput,
  UpdateEstimationRunResponse,
} from '@estimation-tool/shared';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { EstimationRunListItem } from '../../../models/estimation-runs';
import { performGraphqlOperation } from '../../../utils/graphql';

export class EstimationRunsService {
  public async listUserEstimationRuns(): Promise<EstimationRunListItem[]> {
    const { getDataFromUser } = await performGraphqlOperation<{
      getDataFromUser: {
        estimationRuns: EstimationRunListItem[];
      };
    }>(queries.listUserEstimationRuns);

    return getDataFromUser.estimationRuns;
  }

  public async getEstimationRunDetails(id: string): Promise<EstimationRun> {
    const { getEstimationRunDetails } = await performGraphqlOperation<{
      getEstimationRunDetails: EstimationRun;
    }>(queries.getEstimationRunDetails, { id });

    return getEstimationRunDetails;
  }

  public async updateEstimationRun(
    updatedEstimationRun: EstimationRunInput,
  ): Promise<UpdateEstimationRunResponse> {
    const { updateEstimationRun } = await performGraphqlOperation<{
      updateEstimationRun: EstimationRun;
    }>(mutations.updateEstimationRun, {
      updatedEstimationRun,
    });
    return updateEstimationRun;
  }
}
