import { API, graphqlOperation } from 'aws-amplify';

export const performGraphqlOperation = async <T>(
  query: unknown,
  variables?: NonNullable<unknown> | undefined,
): Promise<T> => {
  const { data } = (await API.graphql(graphqlOperation(query, variables))) as {
    data: T;
  };

  return data;
};
