import React, { ReactElement } from 'react';

import LoadingSpinner from '../../loading-spinner';

export interface SuspenseRouteProps {
  component: ReactElement;
}

const SuspenseRoute = ({ component }: SuspenseRouteProps) => {
  return <React.Suspense fallback={<LoadingSpinner />}>{component}</React.Suspense>;
};

export default SuspenseRoute;
