import { PaletteMode } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';

import { createDarkComponents } from './dark/create-dark-components';
import { createDarkPalette } from './dark/create-dark-palette';
import { createDarkShadows } from './dark/create-dark-shadows';
import { createLightComponents } from './light/create-light-components';
import { createLightPalette } from './light/create-light-palette';
import { createLightShadows } from './light/create-light-shadows';
import { createTypography } from './typography';

export const darkPalette = createDarkPalette();
export const lightPalette = createLightPalette();

export const createMaterialTheme = (mode: PaletteMode): Theme => {
  const palette = mode === 'dark' ? darkPalette : lightPalette;
  const components =
    mode === 'dark' ? createDarkComponents(palette) : createLightComponents(palette);
  const shadows = mode === 'dark' ? createDarkShadows() : createLightShadows();
  const typography = createTypography();

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1400,
      },
    },
    palette,
    typography,
    components,
    shadows,
    shape: {
      borderRadius: 8,
    },
  });
};
