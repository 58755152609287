import { EstimationTaskFinalizationInputProperty } from '@estimation-tool/shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompleteEstimationTaskFinalizationError } from '../../models/errors/estimation-tasks/complete-estimation-task-finalization-error';
import { CreateNewEstimationTaskError } from '../../models/errors/estimation-tasks/create-new-estimation-task-error';
import { DeleteEstimationTaskError } from '../../models/errors/estimation-tasks/delete-estimation-task-error';
import { EndEstimationRunError } from '../../models/errors/estimation-tasks/end-estimation-run-error';
import { ExportEstimationTaskError } from '../../models/errors/estimation-tasks/export-estimation-task-error';
import { GetEstimationTaskDetailError } from '../../models/errors/estimation-tasks/get-estimation-task-detail-error';
import { GetEstimationTaskFinalizationError } from '../../models/errors/estimation-tasks/get-estimation-task-finalization-error';
import { ListEstimationTasksError } from '../../models/errors/estimation-tasks/list-estimation-tasks-error';
import { StartEstimationTaskError } from '../../models/errors/estimation-tasks/start-estimation-task-error';
import { UpdateEstimationTaskError } from '../../models/errors/estimation-tasks/update-estimation-task-error';
import { EstimationTaskStep } from '../../models/estimation-tasks/task-details-page';
import { mapTaskToFrontendTask } from '../../utils';
import { createNewGroup } from '../../utils/estimation-task-details';
import {
  AddNewItemPayload,
  DeleteGroupPayload,
  DeleteItemPayload,
  ReorderGroupsPayload,
  ReorderItemsPayload,
  UpdateFinalizationItemPayload,
  UpdateGroupPayload,
  UpdateItemPayload,
  UpdatingFinalizationValuePayload,
} from './action-types';
import { EstimationTasksState } from './estimation-tasks-state';
import {
  handleAddNewGroup,
  handleAddNewItem,
  handleDeleteItem,
  handleGroupDelete,
  handleGroupUpdate,
  handleReorderGroups,
  handleReorderItems,
  handleUpdateFinalizationItem,
  handleUpdateItem,
} from './reducers-logic';
// eslint-disable-next-line import/no-cycle
import {
  completeEstimationTaskEvaluation,
  completeEstimationTaskFinalization,
  createNewEstimationTask,
  deleteEstimationTask,
  endEstimationRun,
  exportEstimationTask,
  getEstimationTaskDetails,
  getEstimationTaskFinalization,
  listUserEstimationTasks,
  startEstimationTask,
  updateEstimationTask,
  updateEstimationTaskProperty,
} from './thunks';

const estimationTasksInitialState: EstimationTasksState = {
  loading: false,
  estimationTasksList: [],
  deletedItemIds: [],
  estimationTaskStep: EstimationTaskStep.DEFINITION,
};

export const estimationTasksSlice = createSlice({
  name: 'estimation-tasks',
  initialState: estimationTasksInitialState,
  reducers: {
    resetError: (state: EstimationTasksState) => {
      state.errorMsg = undefined;
    },
    resetStateToInitial: (state: EstimationTasksState) => {
      state.estimationTasksList = [];
      state.errorMsg = undefined;
      state.loading = false;
      state.estimationTaskDetails = undefined;
      state.deletedItemIds = [];
      state.estimationTaskStep = EstimationTaskStep.DEFINITION;
    },
    addNewGroup: (state: EstimationTasksState) => {
      handleAddNewGroup(state);
    },
    updateGroup: (state: EstimationTasksState, { payload }: PayloadAction<UpdateGroupPayload>) => {
      handleGroupUpdate(state, payload);
    },
    deleteGroup: (state: EstimationTasksState, { payload }: PayloadAction<DeleteGroupPayload>) => {
      handleGroupDelete(state, payload);
    },
    reorderGroups: (
      state: EstimationTasksState,
      { payload }: PayloadAction<ReorderGroupsPayload>,
    ) => {
      handleReorderGroups(state, payload);
    },
    addNewItem: (state: EstimationTasksState, { payload }: PayloadAction<AddNewItemPayload>) => {
      handleAddNewItem(state, payload);
    },
    updateItem: (state: EstimationTasksState, { payload }: PayloadAction<UpdateItemPayload>) => {
      handleUpdateItem(state, payload);
    },
    deleteItem: (state: EstimationTasksState, { payload }: PayloadAction<DeleteItemPayload>) => {
      handleDeleteItem(state, payload);
    },
    reorderItems: (
      state: EstimationTasksState,
      { payload }: PayloadAction<ReorderItemsPayload>,
    ) => {
      handleReorderItems(state, payload);
    },
    updateFinalizationItem: (
      state: EstimationTasksState,
      { payload }: PayloadAction<UpdateFinalizationItemPayload>,
    ) => {
      handleUpdateFinalizationItem(state, payload);
    },
    setEstimationTaskStep: (state: EstimationTasksState, action) => {
      state.estimationTaskStep = action.payload;
    },
    setUpdatingFinalizationValueInGroupPosition(
      state: EstimationTasksState,
      { payload }: PayloadAction<UpdatingFinalizationValuePayload>,
    ) {
      if (
        payload.propertyName === EstimationTaskFinalizationInputProperty.ESTIMATION_FINAL_VALUE &&
        payload.groupPosition !== null &&
        payload.groupPosition !== undefined
      ) {
        state.updatedFinalValueInGroupPosition = payload.groupPosition;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listUserEstimationTasks.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(
      listUserEstimationTasks.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        state.estimationTasksList = payload;
      },
    );
    builder.addCase(listUserEstimationTasks.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new ListEstimationTasksError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(deleteEstimationTask.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(deleteEstimationTask.fulfilled, (state: EstimationTasksState, { payload }) => {
      state.loading = false;
      state.estimationTasksList = state.estimationTasksList.filter((item) => item.id !== payload);
    });
    builder.addCase(deleteEstimationTask.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new DeleteEstimationTaskError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(exportEstimationTask.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(exportEstimationTask.fulfilled, (state: EstimationTasksState) => {
      state.loading = false;
    });
    builder.addCase(exportEstimationTask.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new ExportEstimationTaskError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });

    builder.addCase(createNewEstimationTask.pending, (state: EstimationTasksState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(createNewEstimationTask.fulfilled, (state: EstimationTasksState) => {
      state.loading = false;
    });
    builder.addCase(createNewEstimationTask.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new CreateNewEstimationTaskError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(updateEstimationTask.pending, (state: EstimationTasksState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(updateEstimationTask.fulfilled, (state: EstimationTasksState, { payload }) => {
      state.loading = false;
      const mappedFrontendTask = mapTaskToFrontendTask(payload);
      state.estimationTaskDetails = mappedFrontendTask;
      state.lastSavedEstimationTaskDetails = mappedFrontendTask;
      state.deletedItemIds = [];
    });
    builder.addCase(updateEstimationTask.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new UpdateEstimationTaskError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });

    builder.addCase(getEstimationTaskDetails.pending, (state: EstimationTasksState) => {
      state.loading = true;
      state.estimationTaskDetails = undefined;
      state.errorMsg = undefined;
    });
    builder.addCase(
      getEstimationTaskDetails.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        const mappedFrontendTask = mapTaskToFrontendTask(payload);
        state.estimationTaskDetails = mappedFrontendTask;
        state.lastSavedEstimationTaskDetails = mappedFrontendTask;
        // Enhance user experience by automatically adding an empty group if none exist
        if (state.estimationTaskDetails.groups?.length === 0) {
          state.estimationTaskDetails.groups.push(createNewGroup());
        }
      },
    );
    builder.addCase(getEstimationTaskDetails.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new GetEstimationTaskDetailError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(getEstimationTaskFinalization.pending, (state: EstimationTasksState) => {
      state.loading = true;
      state.estimationTaskFinalization = undefined;
      state.errorMsg = undefined;
    });
    builder.addCase(
      getEstimationTaskFinalization.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        state.estimationTaskFinalization = payload;
      },
    );
    builder.addCase(
      getEstimationTaskFinalization.rejected,
      (state: EstimationTasksState, { error }) => {
        const appError = new GetEstimationTaskFinalizationError(error);
        state.loading = false;
        state.errorMsg = appError.appMessage;
      },
    );
    builder.addCase(startEstimationTask.pending, (state: EstimationTasksState) => {
      state.loading = true;
      state.errorMsg = undefined;
    });
    builder.addCase(startEstimationTask.fulfilled, (state: EstimationTasksState, { payload }) => {
      state.loading = false;
      const mappedFrontendTask = mapTaskToFrontendTask(payload);
      state.estimationTaskDetails = mappedFrontendTask;
      state.lastSavedEstimationTaskDetails = mappedFrontendTask;
    });
    builder.addCase(startEstimationTask.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new StartEstimationTaskError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(completeEstimationTaskEvaluation.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(
      completeEstimationTaskEvaluation.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        if (payload?.status && state.estimationTaskFinalization?.status) {
          state.estimationTaskFinalization.status = payload.status;
        }
      },
    );
    builder.addCase(
      completeEstimationTaskEvaluation.rejected,
      (state: EstimationTasksState, { error }) => {
        const appError = new EndEstimationRunError(error);
        state.loading = false;
        state.errorMsg = appError.appMessage;
      },
    );
    builder.addCase(endEstimationRun.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(endEstimationRun.fulfilled, (state: EstimationTasksState, { payload }) => {
      state.loading = false;
      if (state.estimationTaskFinalization?.assignees) {
        state.estimationTaskFinalization.assignees = payload;
      }
    });
    builder.addCase(endEstimationRun.rejected, (state: EstimationTasksState, { error }) => {
      const appError = new EndEstimationRunError(error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
    builder.addCase(updateEstimationTaskProperty.pending, (state: EstimationTasksState) => {
      state.errorMsg = undefined;
    });
    builder.addCase(
      updateEstimationTaskProperty.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        state.updatedFinalValueInGroupPosition = null;
        if (payload?.status && state.estimationTaskDetails?.status) {
          state.estimationTaskDetails.status = payload.status;
        }
      },
    );
    builder.addCase(
      updateEstimationTaskProperty.rejected,
      (state: EstimationTasksState, { error }) => {
        const appError = new UpdateEstimationTaskError(error);
        state.loading = false;
        state.updatedFinalValueInGroupPosition = null;
        state.errorMsg = appError.appMessage;
      },
    );
    builder.addCase(completeEstimationTaskFinalization.pending, (state: EstimationTasksState) => {
      state.loading = true;
    });
    builder.addCase(
      completeEstimationTaskFinalization.fulfilled,
      (state: EstimationTasksState, { payload }) => {
        state.loading = false;
        if (payload?.status && state.estimationTaskFinalization?.status) {
          state.estimationTaskFinalization.status = payload.status;
        }
      },
    );
    builder.addCase(
      completeEstimationTaskFinalization.rejected,
      (state: EstimationTasksState, { error }) => {
        const appError = new CompleteEstimationTaskFinalizationError(error);
        state.loading = false;
        state.errorMsg = appError.appMessage;
      },
    );
  },
});

export const estimationTasksActions = estimationTasksSlice.actions;
