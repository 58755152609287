export enum RoutePaths {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  CONFIRM_EMAIL = '/confirm-email',
  PROFILE = '/profile',
  SETTINGS = '/settings',
  DASHBOARD = '/dashboard',
  ESTIMATION_TASKS = '/estimation-tasks',
  ESTIMATION_RUN = '/estimation-run',
}
