import React from 'react';
import { Navigate, Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import RedirectToTaskDefinition from './components/routes/redirect-to-task-definition';
import SuspenseRoute from './components/routes/suspense-route/suspense-route';
import { RoutePaths } from './routes-path';

const EstimationTasks = React.lazy(() => import('./pages/estimation-tasks'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));
const EmailConfirmation = React.lazy(() => import('./pages/email-confirmation'));
const EstimationRun = React.lazy(() => import('./pages/estimation-run'));
const EstimationTask = React.lazy(() => import('./pages/estimation-task'));
const EstimationRunDetails = React.lazy(() => import('./pages/estimation-run-details'));
const Profile = React.lazy(() => import('./pages/profile'));
const Settings = React.lazy(() => import('./pages/settings'));
const SignIn = React.lazy(() => import('./pages/sign-in'));
const SignUp = React.lazy(() => import('./pages/sign-up'));

const Routes = () => (
  <ReactRouterRoutes>
    <>
      <Route path={RoutePaths.SIGN_IN} element={<SuspenseRoute component={<SignIn />} />} />
      <Route path={RoutePaths.SIGN_UP} element={<SuspenseRoute component={<SignUp />} />} />

      <Route
        path={RoutePaths.CONFIRM_EMAIL}
        element={<SuspenseRoute component={<EmailConfirmation />} />}
      />

      <Route path={RoutePaths.PROFILE} element={<SuspenseRoute component={<Profile />} />} />
      <Route path={RoutePaths.SETTINGS} element={<SuspenseRoute component={<Settings />} />} />

      <Route path={RoutePaths.DASHBOARD} element={<SuspenseRoute component={<Dashboard />} />} />

      <Route
        path={RoutePaths.ESTIMATION_TASKS}
        element={<SuspenseRoute component={<EstimationTasks />} />}
      />

      {/* TODO @SAM Change the path name */}
      <Route
        path={`${RoutePaths.ESTIMATION_TASKS}/:taskId/process`}
        element={<SuspenseRoute component={<EstimationTask />} />}
      />

      <Route
        path={`${RoutePaths.ESTIMATION_TASKS}/:taskId/*`}
        element={<RedirectToTaskDefinition />}
      />

      <Route
        path={RoutePaths.ESTIMATION_RUN}
        element={<SuspenseRoute component={<EstimationRun />} />}
      />

      <Route
        path={`${RoutePaths.ESTIMATION_RUN}/:runId`}
        element={<SuspenseRoute component={<EstimationRunDetails />} />}
      />

      <Route path="*" element={<Navigate to={RoutePaths.DASHBOARD} replace />} />
    </>
  </ReactRouterRoutes>
);

export default Routes;
