import {
  EstimationRun,
  EstimationRunInput,
  EstimationRunStatus,
  UpdateEstimationRunResponse,
} from '@estimation-tool/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { EstimationRunsService } from '../../features/estimation-runs/services';
import { EstimationRunListItem } from '../../models/estimation-runs';
// eslint-disable-next-line import/no-cycle
import { estimationRunsActions } from './slice';

const estimationRunsService = new EstimationRunsService();

export const listUserEstimationRuns = createAsyncThunk(
  'estimationRuns/listUserEstimationRuns',
  async (): Promise<EstimationRunListItem[]> => {
    return await estimationRunsService.listUserEstimationRuns();
  },
);

export const getEstimationRunDetails = createAsyncThunk(
  'estimationRuns/getEstimationRunDetails',
  async (props: { id: string }): Promise<EstimationRun> => {
    return await estimationRunsService.getEstimationRunDetails(props.id);
  },
);

export const updateEstimationRun = createAsyncThunk(
  'estimationRuns/updateEstimationRun',
  async (
    props: {
      updatedEstimationRun: EstimationRunInput;
    },
    { dispatch },
  ): Promise<UpdateEstimationRunResponse> => {
    const { propertyName, itemId, value } = props.updatedEstimationRun;

    const returnResult = await estimationRunsService.updateEstimationRun(
      props.updatedEstimationRun,
    );

    if (propertyName && itemId && (value || value === '')) {
      dispatch(
        estimationRunsActions.updateEstimationItemValue({
          field: propertyName,
          itemId,
          value,
        }),
      );
    }

    return returnResult;
  },
);

export const submitEstimationRun = createAsyncThunk(
  'estimationRuns/submitEstimationRun',
  async (props: { runId: string; onSuccess: () => void }): Promise<UpdateEstimationRunResponse> => {
    const res = await estimationRunsService.updateEstimationRun({
      runId: props.runId,
      status: EstimationRunStatus.COMPLETED,
    });
    props.onSuccess();
    return res;
  },
);
