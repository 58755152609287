export const deleteEstimationTask = /* GraphQL */ `
  mutation deleteEstimationTask($id: String) {
    deleteEstimationTask(id: $id)
  }
`;

export const exportEstimationTask = /* GraphQL */ `
  mutation exportEstimationTask($id: String, $excelParameters: ExcelParametersInput) {
    exportEstimationTask(id: $id, excelParameters: $excelParameters)
  }
`;

export const createEstimationTask = /* GraphQL */ `
  mutation createEstimationTask($newEstimationTask: EstimationTaskInput) {
    createEstimationTask(newEstimationTask: $newEstimationTask) {
      id
    }
  }
`;

export const updateEstimationTask = /* GraphQL */ `
  mutation updateEstimationTask($updatedEstimationTask: UpdateEstimationTaskInput) {
    updateEstimationTask(updatedEstimationTask: $updatedEstimationTask) {
      id
      name
      shortDescription
      description
      status
      labels
      assignees {
        id
        name
      }
      createdAt
      updatedAt
      groups {
        name
        description
        position
        color
        items {
          id
          name
          description
          position
        }
      }
    }
  }
`;

export const startEstimationTask = /* GraphQL */ `
  mutation startEstimationTask($startingEstimationTask: String) {
    startEstimationTask(id: $startingEstimationTask) {
      id
      name
      shortDescription
      description
      status
      labels
      assignees {
        id
        name
      }
      createdAt
      updatedAt
      groups {
        name
        description
        position
        color
        items {
          id
          name
          description
          position
        }
      }
    }
  }
`;

export const updateEstimationRun = /* GraphQL */ `
  mutation updateEstimationRun($updatedEstimationRun: EstimationRunInput) {
    updateEstimationRun(updatedEstimationRun: $updatedEstimationRun) {
      status
    }
  }
`;

export const endEstimationRun = /* GraphQL */ `
  mutation endEstimationRun($id: String) {
    endEstimationRun(id: $id) {
      id
      name
      status
      completedItems
    }
  }
`;

export const updateEstimationTaskProperty = /* GraphQL */ `
  mutation updateEstimationTaskProperty($updatedEstimationTask: EstimationTaskPropertyInput) {
    updateEstimationTaskProperty(updatedEstimationTask: $updatedEstimationTask) {
      status
    }
  }
`;
