import { createSlice } from '@reduxjs/toolkit';

import { ThemeState } from './theme-state';

const themeInitialState: ThemeState = {
  mode: 'light',
  isMiniSidebar: true,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState: themeInitialState,
  reducers: {
    setTheme: (state: ThemeState, action) => {
      state.mode = action.payload;
    },
    setMiniSidebarStatus: (state: ThemeState, { payload }) => {
      state.isMiniSidebar = payload;
    },
  },
});

export const themeActions = themeSlice.actions;
