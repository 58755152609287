import { EstimationRunInputProperty } from '@estimation-tool/shared';

import { UpdateEstimationItemValuePayload } from './action-types';
import { EstimationRunState } from './estimation-run-state';

/**
 * Updates the value of a specified estimation item within the estimation run state.
 */

export const updateEstimationItemValue = (
  state: EstimationRunState,
  payload: UpdateEstimationItemValuePayload,
) => {
  const { field, value, itemId } = payload;

  const item = state.estimationRunDetails?.items.byId[itemId];

  if (item) {
    // If the field is a comment, update directly with the string value; otherwise, parse numeric values.
    if (field === EstimationRunInputProperty.COMMENT) {
      item[field] = value || undefined;
    } else {
      item[field] = value ? +value : undefined;
    }
  }
};
