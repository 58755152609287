import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '../app-error';

export class SignInError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.sign-in-error');
    if (error?.code === 'NotAuthorizedException') {
      this.appMessage = 'errors.auth.sign-in-not-authorized';
    }
    if (error?.code === 'UserNotConfirmedException') {
      this.appMessage = 'errors.auth.email-not-confirmed';
    }
    if (error?.code === 'UserNotFoundException') {
      this.appMessage = 'errors.auth.no-account-found';
    }
  }
}
