import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '../app-error';

export class ConfirmSignUpError extends AppError {
  constructor(error: SerializedError) {
    super(error, 'errors.auth.confirm-signup-error');

    if (error?.code === 'CodeMismatchException') {
      this.appMessage = 'errors.auth.code-not-valid';
    }

    if (error?.code === 'ExpiredCodeException') {
      this.appMessage = 'errors.auth.code-expired';
    }
  }
}
