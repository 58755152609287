export var ExcelParameters;
(function (ExcelParameters) {
    ExcelParameters["position"] = "position";
    ExcelParameters["name"] = "name";
    ExcelParameters["description"] = "description";
    ExcelParameters["min"] = "min";
    ExcelParameters["max"] = "max";
    ExcelParameters["mean"] = "mean";
    ExcelParameters["estimationFinalValue"] = "estimationFinalValue";
    ExcelParameters["taskOwnerComment"] = "taskOwnerComment";
})(ExcelParameters || (ExcelParameters = {}));
