import { User } from '@estimation-tool/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserListService } from '../../services';

const userListService = new UserListService();

export const listUsers = createAsyncThunk('user-list/listUsers', async (): Promise<User[]> => {
  return await userListService.listUsers();
});
