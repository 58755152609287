import { EstimationTaskFinalizationInputProperty, FinalizationItem } from '@estimation-tool/shared';

import { FrontendTaskGroup } from '../../models/estimation-tasks/frontend-task-group';
import { reorder, reorderItemsWithinGroups } from '../../utils/drag-and-drop';
import {
  createNewGroup,
  createNewItem,
  extractIdsFromTaskItems,
} from '../../utils/estimation-task-details';
import {
  AddNewItemPayload,
  DeleteGroupPayload,
  DeleteItemPayload,
  ReorderGroupsPayload,
  ReorderItemsPayload,
  UpdateFinalizationItemPayload,
  UpdateGroupPayload,
  UpdateItemPayload,
} from './action-types';
import { EstimationTasksState } from './estimation-tasks-state';

// Group Actions
export const handleAddNewGroup = (state: EstimationTasksState) => {
  const newGroup = createNewGroup();
  state.estimationTaskDetails?.groups.push(newGroup);
};

export const handleGroupUpdate = (state: EstimationTasksState, payload: UpdateGroupPayload) => {
  const { field, value, groupIndex } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (groupIndex < 0 || groupIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const { groups } = state.estimationTaskDetails;
  groups[groupIndex][field] = value;
};

export const handleGroupDelete = (state: EstimationTasksState, payload: DeleteGroupPayload) => {
  const { groupIndex } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (groupIndex < 0 || groupIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const deletedGroup = state.estimationTaskDetails.groups[groupIndex];
  const itemIds = extractIdsFromTaskItems(deletedGroup.items);

  state.deletedItemIds.push(...itemIds);
  state.estimationTaskDetails.groups.splice(groupIndex, 1);
};

export const handleReorderGroups = (state: EstimationTasksState, payload: ReorderGroupsPayload) => {
  const { startIndex, endIndex } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (startIndex < 0 || startIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  if (endIndex < 0 || endIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const { groups } = state.estimationTaskDetails;

  state.estimationTaskDetails.groups = reorder<FrontendTaskGroup>(groups, startIndex, endIndex);
};

// Item Actions
export const handleAddNewItem = (state: EstimationTasksState, payload: AddNewItemPayload) => {
  const { groupIndex, position } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (groupIndex < 0 || groupIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const newItem = createNewItem();
  const { groups } = state.estimationTaskDetails;
  groups[groupIndex].items.splice(position, 0, newItem);
};

export const handleUpdateItem = (state: EstimationTasksState, payload: UpdateItemPayload) => {
  const { field, groupIndex, itemIndex, value } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (groupIndex < 0 || groupIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const { groups } = state.estimationTaskDetails;

  if (itemIndex < 0 || itemIndex >= state.estimationTaskDetails.groups[groupIndex].items?.length) {
    return; // Return void if itemIndex is out of bounds
  }
  const item = groups[groupIndex].items[itemIndex];
  item[field] = value;
};

export const handleDeleteItem = (state: EstimationTasksState, payload: DeleteItemPayload) => {
  const { groupIndex, itemIndex } = payload;

  if (!state.estimationTaskDetails || !state.estimationTaskDetails.groups) {
    return; // Return void if estimationTaskDetails or groups are missing
  }

  if (groupIndex < 0 || groupIndex >= state.estimationTaskDetails.groups?.length) {
    return; // Return void if groupIndex is out of bounds
  }

  const { groups } = state.estimationTaskDetails;

  if (itemIndex < 0 || itemIndex >= state.estimationTaskDetails.groups[groupIndex].items?.length) {
    return; // Return void if itemIndex is out of bounds
  }

  const selectedGroup = groups[groupIndex];

  const selectedItem = selectedGroup.items[itemIndex];

  if (selectedItem.id) state.deletedItemIds.push(selectedItem.id);

  selectedGroup.items.splice(itemIndex, 1);
};

export const handleReorderItems = (state: EstimationTasksState, payload: ReorderItemsPayload) => {
  const { itemDestinationIndex, itemSourceIndex, sourceDroppableId, destinationDroppableId } =
    payload;

  if (state.estimationTaskDetails) {
    const { groups } = state.estimationTaskDetails;
    state.estimationTaskDetails.groups = reorderItemsWithinGroups(
      groups,
      itemSourceIndex,
      itemDestinationIndex,
      sourceDroppableId,
      destinationDroppableId,
    );
  }
};
export const handleUpdateFinalizationItem = (
  state: EstimationTasksState,
  payload: UpdateFinalizationItemPayload,
) => {
  const { field, value, itemId } = payload;
  // TODO @Laurin Change variable names
  const allItems = state.estimationTaskFinalization?.groups.reduce(
    (items: FinalizationItem[], group) => {
      items.push(...group.items);
      return items;
    },
    [],
  );
  if (!allItems) {
    return;
  }
  const finalizationItem = allItems?.find((item) => item.id === itemId);

  if (finalizationItem) {
    if (field === EstimationTaskFinalizationInputProperty.TASK_OWNER_COMMENT) {
      finalizationItem[field] = value || undefined;
    } else {
      finalizationItem[field] = value ? +value : undefined;
    }
  }
};
